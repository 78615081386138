import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AOS from 'aos'
import { isBrowser } from 'react-device-detect'
import '@fortawesome/fontawesome-free/js/all.js'

import { HomePage } from 'pages/home'
import { About } from 'pages/about'
import PortfolioPage from 'pages/portfolio/portfolio'
import { ContactPage } from 'pages/contact/contact'
import WorkPage from 'pages/work/work'
import { PorcelainPage } from 'pages/porcelain'
import { ElevatorPage } from 'pages/elevator'
import { RoofPage } from 'pages/roof'

import ScrollToTop from 'shared/components/scroll-to-top'
import { NavBar } from 'shared/components/nav-bar/nav-bar'
import Footer from 'shared/components/footer/footer'
import { ContactBox } from 'shared/components/contact-box'

import 'aos/dist/aos.css'
import './App.scss'

export const AppContext = React.createContext({
  imgOverlaySrc: null,
  setImgOverlaySrc: () => {},
})

function App() {
  const [imgOverlaySrc, setImgOverlaySrc] = useState(null)

  AOS.init({
    easing: 'ease-out',
    duration: 500,
    once: true,
    delay: isBrowser ? 300 : 0,
  })

  return (
    <AppContext.Provider value={{ imgOverlaySrc, setImgOverlaySrc }}>
      <Router>
        <ContactBox />
        <NavBar />
        <ScrollToTop>
          <Switch>
            <Route path="/work/:id">
              <WorkPage />
            </Route>

            <Route path="/portfolio/:typeId?">
              <PortfolioPage />
            </Route>

            <Route path="/about">
              <About />
            </Route>

            <Route path="/contact">
              <ContactPage />
            </Route>

            <Route path="/porcelain">
              <PorcelainPage />
            </Route>

            <Route path="/elevator">
              <ElevatorPage />
            </Route>

            <Route path="/roof">
              <RoofPage />
            </Route>

            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </ScrollToTop>

        <Footer />
      </Router>
    </AppContext.Provider>
  )
}

export default App
