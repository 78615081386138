import React, { useState, memo, useEffect } from 'react'
import { string } from 'prop-types'

import './media-card.scss'

function MediaCard({ imgUri, workName, workType }) {
  const [orientation, setOrientation] = useState('')

  useEffect(() => {
    if (imgUri) {
      let orientation = ''
      const img = new Image()
      img.src = imgUri

      if (img.naturalWidth > img.naturalHeight) {
        orientation = 'landscape'
      } else if (img.naturalWidth < img.naturalHeight) {
        orientation = 'portrait'
      } else {
        orientation = 'even'
      }

      setOrientation(orientation)
    }
  }, [imgUri])

  return (
    <div className={`media-card ${orientation}`}>
      <div style={{ background: 'rgba(77, 77, 77, 1)' }}>
        <img className="media-card-img" src={imgUri} alt="media card" />
      </div>

      <div className="media-card-description">
        <p className="media-card-description-text">{workName}</p>
        <p className="media-card-description-tag">{workType}</p>
      </div>
    </div>
  )
}

MediaCard.propTypes = {
  imgUri: string,
  workName: string,
  workType: string,
}

export default memo(MediaCard)
