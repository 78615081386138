import React from 'react'
import { Helmet } from 'react-helmet'

import { ImageZoom } from 'shared/components/image-zoom'
import ParallaxSection from 'shared/components/parallax-section/parallax-section'

import imageData from './elevator-data.json'
import './elevator.scss'

const backgroundImage = require('assets/imgs/elevators.jpg')

export function ElevatorPage() {
  return (
    <div className="elevator">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Elevadores</title>
        <meta
          name="description"
          content="A Construtora Execução Engenharia é uma empresa especializada em modernização de elevadores, que visa
      garantir um atendimento personalizado, oferecendo qualidade, alto padrão técnico, design
      moderno e acima de tudo pontualidade."
        />
        <meta property="og:url" content="https://www.execucao-engenharia.com.br/elevator" />
        <link rel="canonical" href="https://www.execucao-engenharia.com.br/elevator"></link>
      </Helmet>

      <section className="elevator-section-parallax">
        <ParallaxSection title="ELEVADORES" backgroundImage={`url(${backgroundImage})`} />
      </section>

      <section className="elevator-section-text">
        <div className="container">
          <h3 className="elevator-section-text-title" data-aos="fade-in">
            Design / Embelezamento de Elevadores
          </h3>
          <p data-aos="fade-in">
            Conheça o nosso diferencial e descubra porque somos uma empresa especializada em reforma
            e decoração de elevadores.
          </p>
          <p data-aos="fade-in">
            A Construtora Execução Engenharia é uma empresa especializada em modernização de elevadores, que
            visa garantir um atendimento personalizado, oferecendo qualidade, alto padrão técnico,
            design moderno e acima de tudo pontualidade.
          </p>
        </div>
      </section>

      <section className="porcelain-grid-section">
        {imageData.map((image, index) => {
          return <ImageZoom key={index} imgUri={image.uri} />
        })}
      </section>
    </div>
  )
}
