import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import ParallaxSection from 'shared/components/parallax-section/parallax-section'
import './contact.scss'

const backgroundImage = require('assets/imgs/contact-us.jpeg')

export function ContactPage() {
  const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false)

  function onSubmit(e) {
    e.preventDefault()

    const { inputName, inputEmail, inputCompany, inputSubject, inputMessage } = e.target.elements
    const requestData = {
      name: inputName.value,
      senderEmail: inputEmail.value,
      company: inputCompany.value,
      subject: inputSubject.value,
      message: inputMessage.value,
    }

    fetch('https://i91d1yrc0e.execute-api.sa-east-1.amazonaws.com/default/SendContactEmail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    }).finally(() => {
      setDisplaySuccessMsg(true)
      window.scrollTo({
        top: window.scrollTo({ top: window.innerHeight, behavior: 'smooth' }),
        behavior: 'smooth',
      })
    })
  }

  return (
    <div className="contact">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contato</title>
        <meta
          name="description"
          content="Preencha o formulário para nos enviar sua mensagem, currículo ou apresentação
          comercial"
        />
        <meta property="og:url" content="https://www.execucao-engenharia.com.br/contact" />
        <link rel="canonical" href="https://www.execucao-engenharia.com.br/contact"></link>
      </Helmet>

      <section className="contact-section-1">
        <ParallaxSection title="FALE CONOSCO" backgroundImage={`url(${backgroundImage})`} />
      </section>

      <section className="contact-section-2">
        <div className="container">
          <div className=" row justify-content-center">
            <div className="col-md-6">
              <h3>PEÇA JÁ SEU ORÇAMENTO</h3>

              <p>
                Preencha o formulário abaixo para nos enviar sua mensagem, currículo ou apresentação
                comercial:
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className=" row justify-content-center">
            <div className="col-md-6">
              {displaySuccessMsg ? (
                <div className="alert alert-success" role="alert">
                  Formulário enviado com sucesso!
                </div>
              ) : (
                false
              )}
              <form id="contactForm" onSubmit={onSubmit}>
                <div className="form-group">
                  <label for="inputName">Nome *</label>
                  <input type="text" className="form-control" id="inputName" required />
                </div>

                <div className="form-group">
                  <label for="inputEmail">E-mail *</label>
                  <input type="email" className="form-control" id="inputEmail" required />
                </div>

                <div className="form-group">
                  <label for="inputCompany">Empresa *</label>
                  <input type="text" className="form-control" id="inputCompany" required />
                </div>

                <div className="form-group">
                  <label for="inputSubject">Assunto</label>
                  <input type="text" className="form-control" id="inputSubject" />
                </div>

                <div className="form-group">
                  <label for="inputMessage">Mensagem *</label>
                  <textarea className="form-control" id="inputMessage" rows="5" required></textarea>
                </div>

                <button type="submit" className="btn btn-primary">
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

ContactPage.propTypes = {}
