import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import { Helmet } from 'react-helmet'

import ParallaxSection from 'shared/components/parallax-section/parallax-section'
import MediaCard from 'shared/components/media-card/media-card'
import './portfolio.scss'
import data from 'assets/imgs/works'

const backgroundImage = require('assets/imgs/portfolio.jpg')

const types = [
  { id: 0, name: 'Completo' },
  { id: 2, name: 'Comercial' },
  { id: 3, name: 'Residencial' },
]

function PortfolioPage() {
  const { typeId: paramTypeId = 0 } = useParams()
  const [typeId, setTypeId] = useState(Number(paramTypeId))
  const currentWork = types.find((type) => type.id === typeId)

  const works =
    typeId === 0
      ? data
      : data.filter((obj) => {
          const type = types.find((tp) => tp.id === typeId)
          return obj.type === type.name
        })
  return (
    <div className="portfolio">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfólio - Construtora Execução Engenharia</title>
        <meta
          name="description"
          content="A Construtora Execução Engenharia vem se distinguindo na execução de projetos em alto grau. Conheça nosso portfólio!"
        />
        <meta
          property="og:image"
          content="https://www.execucao-engenharia.com.br/static/media/portfolio.7ed65e92.jpg"
        />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:width" content="2000" />
        <meta property="og:height" content="1335" />
        <link rel="canonical" href="https://www.execucao-engenharia.com.br/portfolio"></link>
      </Helmet>

      <section className="portfolio-section-1">
        <ParallaxSection title="NOSSOS PROJETOS" backgroundImage={`url(${backgroundImage})`} />
      </section>

      <section className="portfolio-section-2">
        <div className="d-none d-md-block">
          <div className="portfolio-section-2-types-md">
            {types.map(({ name, id }) => (
              <button key={id} onClick={() => setTypeId(id)}>
                <span className={`${id === typeId ? 'selected' : ''}`}>{name}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="portfolio-section-2-types-sm d-block d-sm-none">
          <div className="dropdown">
            <button
              className="btn btn-lg btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              {`Portfólio ${currentWork.name}`}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {types.map(({ name, id }) => (
                <span key={id} onClick={() => setTypeId(id)} className="dropdown-item">
                  {name}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="portfolio-section-2-grid">
          {works.map((work) => {
            const cover = work.images.find((img) => img.uri.includes('cover'))

            return (
              <Link key={work.id} to={`/work/${work.id}`}>
                <LazyLoad height="100%" once>
                  <MediaCard workName={work.name} workType={work.type} imgUri={cover.uri} />
                </LazyLoad>
              </Link>
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default PortfolioPage
