import React from 'react'

import './footer.scss'

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row company align-items-center">
          <div className="col-sm-12 col-md-6">
            <p className="company-email" data-aos="fade-in" data-aos-offset="-200">
              execucaoengenharia01@gmail.com
            </p>
            <p className="company-detail" data-aos="fade-in">
              Itaboraí, Rio de janeiro
              <br />
              (21) 99663-5462
            </p>
            <div className="company-share" data-aos="fade-in" data-aos-offset="-200">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/execucao-engenharia/?ref=bookmarks">
                <img alt="facebook" src={require('assets/imgs/icons/facebook-f-brand.png')} />
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCT_VGTEC8dheWkybMG4Tjqg">
                <img alt="youtube" src={require('assets/imgs/icons/youtube-brand.png')} />{' '}
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/will_construtor/">
                <img alt="instagram" src={require('assets/imgs/icons/instagram-brand.png')} />{' '}
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=5521996635462&text=Olá!%20Encontrei%20seu%20site%20na%20Internet%20e%20preciso%20de%20seus%20serviços.">
                <img alt="whatsapp" src={require('assets/imgs/icons/whatsapp-brand.png')} />{' '}
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 d-none d-md-block">
            <img
              alt="logo"
              className="logo"
              src={require('assets/imgs/logo.webp')}
              data-aos="fade-in"
            />
          </div>
        </div>

        <p className="rights" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="1000">
          @2020 all rights reserved
        </p>
      </div>
    </footer>
  )
}

export default Footer
