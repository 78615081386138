import React from 'react'
import { isBrowser } from 'react-device-detect'

import Menu from '../menu/menu'
import './nav-bar.scss'

const logo = require('assets/imgs/logo.webp')
const logoImgOnly = require('assets/imgs/logo-img-only.webp')
export function NavBar() {
  return (
    <nav className="navBar" data-aos="fade-in">
      <div className="navBar-content">
        <img className="navBar-content-logo" alt="logo" src={isBrowser ? logo : logoImgOnly} />
        <Menu />
      </div>
    </nav>
  )
}
