import React, { useEffect, useState } from 'react'
import { isBrowser } from 'react-device-detect'
import { withRouter } from 'react-router'

var debounce = require('lodash.debounce')

const ScrollToTop = ({ location, children }) => {
  const [displayArrowUp, setDisplayArrowUp] = useState(false)

  useEffect(() => {
    if (location.state?.keepScrollState) return
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > window.screen.height && !displayArrowUp) {
        setDisplayArrowUp(true)
      }

      if (window.scrollY < window.screen.height && displayArrowUp) {
        setDisplayArrowUp(false)
      }
    }

    window.addEventListener('scroll', debounce(handleScroll, 200))

    return () => window.removeEventListener('scroll', handleScroll)
  }, [displayArrowUp])

  return (
    <>
      {children}

      {!isBrowser && displayArrowUp ? <span></span> : null}
    </>
  )
}

export default withRouter(ScrollToTop)
