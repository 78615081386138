import React from 'react'
import { Helmet } from 'react-helmet'

import { ImageZoom } from 'shared/components/image-zoom'
import ParallaxSection from 'shared/components/parallax-section/parallax-section'

import imageData from './porcelain-data.json'
import './porcelain.scss'

const backgroundImage = require('assets/imgs/porcelain-building-interior.jpg')

export function PorcelainPage() {
  return (
    <div className="porcelain">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Porcelanataria</title>
        <meta property="og:url" content="https://www.execucao-engenharia.com.br/porcelain" />
        <meta
          name="description"
          content="A Porcelanataria nasce para quebrar paradigmas e mostrar que o porcelanato pode ser
          muito mais do que um simples piso. Equipada com maquinários modernos desenvolvidos para
          trabalhar especialmente com porcelanato, a Porcelanataria se dedica essencialmente em
          cortes especiais, apresentando a mesma perfeição das grandes fábricas."
        />
      </Helmet>

      <section className="porcelain-section-parallax">
        <ParallaxSection title="PORCELANATARIA" backgroundImage={`url(${backgroundImage})`} />
      </section>

      <section className="porcelain-section-text">
        <div className="container">
          <h3 className="porcelain-section-text-title" data-aos="fade-in">
            PORCELANATOS GRANDES FORMATOS
          </h3>
          <p data-aos="fade-in">
            A Porcelanataria nasce para quebrar paradigmas e mostrar que o porcelanato pode ser
            muito mais do que um simples piso. Equipada com maquinários modernos desenvolvidos para
            trabalhar especialmente com porcelanato, a Porcelanataria se dedica essencialmente em
            cortes especiais, apresentando a mesma perfeição das grandes fábricas.
          </p>
          <p data-aos="fade-in">Um serviço inovador, diferenciado e personalizado.</p>
        </div>
      </section>

      <section className="porcelain-grid-section">
        {imageData.map((image, index) => {
          return <ImageZoom key={index} imgUri={image.uri} />
        })}
      </section>
    </div>
  )
}
