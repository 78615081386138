import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CountUp } from 'countup.js'
import parse from 'html-react-parser'
import { isBrowser } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import data from 'assets/imgs/works'
import './work.scss'
import Separator from 'shared/components/separator/separator'
import { Carousel } from 'react-responsive-carousel'
import { ImageZoom } from 'shared/components/image-zoom'

function WorkPage() {
  const [galleryIsAutoPlaying, setGalleryIsAutoPlaying] = useState(true)
  const [overlay, setOverlay] = useState({ isVisible: false, imageSrc: null })
  const overlayCarouselRef = useRef(null)

  useEffect(() => {
    if (overlay.isVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [overlay])

  let { id } = useParams()
  const work = data.find((item) => item.id === id)
  const coverImage = work.images.find((image) => image.uri.includes('cover'))

  useEffect(() => {
    const evt = document.addEventListener('aos:in', ({ detail }) => {
      if (!detail.dataset.targetId || !detail.dataset.endValue) return

      const countUp = new CountUp(detail.dataset.targetId, detail.dataset.endValue)
      if (!countUp.error) {
        setTimeout(() => countUp.start(), 1000)
      } else {
        console.error(countUp.error)
      }
    })

    return () => document.removeEventListener('aos:in', evt)
  }, [])

  return (
    <div className="work">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Obra {work.name} - Portfólio Construtora Execução Engenharia</title>
        <meta
          name="description"
          content={`Obras executadas com excelência, conheça o trabalho executado pela Construtora Execução Engenharia no ${work.location}.`}
        />
        <meta property="og:url" content={`https://www.execucao-engenharia.com.br/work/${id}`} />
        <link rel="canonical" href={`https://www.execucao-engenharia.com.br/work/${id}`}></link>
      </Helmet>

      <div className={`work-overlay ${overlay.isVisible ? 'visible' : 'hidden'}`}>
        <div className="work-overlay-menu">
          <div
            className="hamburger open"
            onClick={() => setOverlay({ isVisible: false, imageSrc: null })}>
            <span />
            <span />
          </div>
        </div>
        <Carousel
          className="work-overlay-carousel"
          showThumbs
          showArrows={false}
          autoPlay={false}
          showIndicators={false}
          showStatus={false}
          ref={overlayCarouselRef}>
          {work.images.map((img, index) => (
            <div key={index} className="slide-content">
              <img className="slide-content-image" src={img.uri} alt="carousel media" />
            </div>
          ))}
        </Carousel>
      </div>

      <section className="work-section-1">
        <div
          className="parallax"
          data-aos="fade-in-0-7"
          data-aos-easing="ease-out"
          style={{ backgroundImage: `url(${coverImage.uri}` }}
        />
        <div className="parallax-content">
          <p
            className="parallax-content-subtitle"
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-delay="500">
            Obra {work.type}
          </p>
          <p
            className="parallax-content-title"
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="1000">
            {work.name}
          </p>
        </div>
        <div className="parallax-footer">
          <button
            onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
            className="parallax-footer-button">
            <p
              className="parallax-footer-button-text"
              data-aos-offset="-500"
              data-aos="fade-up"
              data-aos-delay="1200">
              SCROLL
            </p>
            <img
              src={require('assets/imgs/icons/down-chevron.svg')}
              alt="arrow down"
              data-aos-offset="-500"
              data-aos="fade-up"
              data-aos-delay="1200"
            />
          </button>
        </div>
      </section>

      <section className="work-section-4" data-aos="fade-in" data-aos-easing="ease-out">
        <div className="container">
          <h2 className="work-section-4-title">Galeria:</h2>

          <CarouselProvider
            visibleSlides={isBrowser ? 3 : 1}
            totalSlides={work.images.length}
            step={1}
            naturalSlideWidth={400}
            naturalSlideHeight={300}
            isPlaying={galleryIsAutoPlaying}
            infinite>
            <Slider
              className="work-gallery"
              onMouseLeave={() => setGalleryIsAutoPlaying(true)}
              onMouseEnter={() => setGalleryIsAutoPlaying(false)}>
              {work.images.map((image, index) => (
                <Slide key={index}>
                  <ImageZoom imgUri={image.uri} />
                </Slide>
              ))}
            </Slider>
            <ButtonBack className="button-prev">
              <img src={require('assets/imgs/icons/left-chevron.png')} alt="arrow down" />
            </ButtonBack>
            <ButtonNext className="button-next">
              <img src={require('assets/imgs/icons/right-chevron.png')} alt="arrow down" />
            </ButtonNext>
          </CarouselProvider>
        </div>
      </section>

      <div className="container">
        <Separator width="80%" />
      </div>

      <section className="work-section-2 containerMediaLeft">
        <div className="container">
          <div className="row">
            <div
              className="col-md col-sm-12  containerMediaLeft-imgContainer"
              style={{ order: isBrowser ? 1 : 2 }}>
              <img
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-offset="250"
                src={work.images[1].uri}
                alt="work img 1"
              />
            </div>

            <div
              className="col-md col-sm-12 containerMediaLeft-text"
              data-aos-offset="250"
              {...(isBrowser
                ? {
                    'data-aos': 'fade-left',
                    'data-aos-offset': '250',
                  }
                : {
                    'data-aos': 'fade',
                    'data-aos-offset': '150',
                  })}
              style={{ order: isBrowser ? 2 : 1 }}>
              {work.text1}
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <Separator width="80%" />
      </div>

      <section className="work-section-3 containerMediaRight">
        <div className="container">
          <div className="row">
            <div
              className="col-sm containerMediaRight-text"
              data-aos-offset="250"
              {...(isBrowser
                ? {
                    'data-aos': 'fade-right',
                    'data-aos-offset': '250',
                  }
                : {
                    'data-aos': 'fade',
                    'data-aos-offset': '150',
                  })}
              id="spaceArea"
              data-target-id="workSpaceArea"
              data-end-value={`${work.spaceArea}`}>
              <p>
                {parse(work.text2.replace('$[spaceArea]', `<span id="workSpaceArea">0</span>`))}
              </p>
            </div>
            <div className="col-sm containerMediaRight-imgContainer">
              <img
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-offset="250"
                src={work.images[2].uri}
                alt="work img 1"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <Separator width="80%" />
      </div>

      <section className="work-section-3 containerMediaLeft">
        <div className="container">
          <div className="row">
            <div
              className="col-sm containerMediaLeft-imgContainer"
              style={{ order: isBrowser ? 1 : 2 }}>
              <img
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-offset="250"
                src={work.images[3].uri}
                alt="work img 1"
              />
            </div>

            <div
              className="col-sm  containerMediaLeft-text"
              {...(isBrowser
                ? {
                    'data-aos': 'fade-left',
                    'data-aos-offset': '250',
                  }
                : {
                    'data-aos': 'fade',
                    'data-aos-offset': '150',
                  })}
              data-target-id="workDuration"
              data-end-value={`${work.durationInDays}`}
              style={{ order: isBrowser ? 2 : 1 }}>
              <p>
                {parse(work.text3.replace('$[durationInDays]', `<span id="workDuration">0</span>`))}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default WorkPage
