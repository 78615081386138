import React from 'react'
import { string } from 'prop-types'

import './work-card.scss'

function WorkCard({ imgSrc, title, subtitle }) {
  return (
    <div className="workCard">
      <img className="workCard-img" src={imgSrc} alt="work" />

      <div className="workCard-textContainer">
        <h3 className="workCard-textContainer-title">{title}</h3>
        <p className="workCard-textContainer-subtitle">{subtitle}</p>
      </div>
    </div>
  )
}

WorkCard.propTypes = {
  imgSrc: string,
  title: string,
  subtitle: string,
}

export default WorkCard
