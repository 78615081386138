import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import usePrevious from 'shared/hooks/use-previous'
import './menu.scss'

function Menu() {
  const [isOpen, setIsOpen] = useState(false)
  const [isFullyOpen, setIsFullyOpen] = useState(false)
  const previousIsOpen = usePrevious(isOpen)

  useEffect(() => {
    if (isOpen !== previousIsOpen) {
      if (isOpen) {
        const timeout = setTimeout(() => setIsFullyOpen(true), 200)
        return () => clearTimeout(timeout)
      } else {
        setIsFullyOpen(false)
      }
    }
  }, [isOpen, previousIsOpen])

  return (
    <>
      <div className="menu">
        <div
          className={`hamburger ${isOpen ? 'open' : ''}`}
          onClick={() => setIsOpen((prev) => !prev)}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>

      <div className={`menu-overlay ${isOpen ? 'open' : ''}`}>
        <div className="menu-overlay-content">
          {isFullyOpen && (
            <nav className="menu-overlay-content-links">
              <Link onClick={() => setIsOpen(false)} to="/">
                Início
              </Link>
              <Link onClick={() => setIsOpen(false)} to="/about">
                Quem Somos
              </Link>
              <Link onClick={() => setIsOpen(false)} to="/portfolio">
                Portfólio
              </Link>
              <Link onClick={() => setIsOpen(false)} to="/porcelain">
                Porcelanataria
              </Link>
              <Link onClick={() => setIsOpen(false)} to="/roof">
                Telhado
              </Link>
              <Link onClick={() => setIsOpen(false)} to="/elevator">
                Elevadores
              </Link>
              {/* <Link onClick={() => setIsOpen(false)} to="/">
                Telhado
              </Link>
             
              <Link onClick={() => setIsOpen(false)} to="/">
                Elevadores
              </Link> */}
              <Link onClick={() => setIsOpen(false)} to="/contact">
                Contato
              </Link>
            </nav>
          )}

          {isFullyOpen && (
            <nav className="menu-overlay-content-footer">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/execucao-engenharia/?ref=bookmarks">
                <img alt="facebook" src={require('assets/imgs/icons/facebook-f-brand.png')} />
              </a>
              {/* <a href="#">
              <img alt="linkedin" src={require('assets/imgs/icons/linkedin-brand.png')} />
            </a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCT_VGTEC8dheWkybMG4Tjqg">
                <img alt="youtube" src={require('assets/imgs/icons/youtube-brand.png')} />{' '}
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/will_construtor/">
                <img alt="instagram" src={require('assets/imgs/icons/instagram-brand.png')} />{' '}
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=5521996635462&text=Olá!%20Encontrei%20seu%20site%20na%20Internet%20e%20preciso%20de%20seus%20serviços.">
                <img alt="whatsapp" src={require('assets/imgs/icons/whatsapp-brand.png')} />{' '}
              </a>
            </nav>
          )}
        </div>
      </div>
    </>
  )
}

export default Menu
