import React, { memo } from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import LazyLoad from 'react-lazyload'

import { useImageOrientation } from 'shared/hooks/use-img-orientation'
import './image.zoom.scss'

export const ImageZoom = memo(({ imgUri, onClick }) => {
  const orientation = useImageOrientation(imgUri)
  const isRemoteUriPath = imgUri.includes('http') || imgUri.includes('www')

  return (
    <div className={`image-zoom ${orientation}`}>
      <Zoom overlayBgColorEnd="rgba(0, 0, 0, 0.85)" zoomMargin={40} wrapStyle={{ width: '100%' }}>
        <LazyLoad height="100%" once>
          <img
            className="image-zoom-img"
            src={isRemoteUriPath ? imgUri : require(imgUri)}
            alt="media card"
            onClick={onClick}
          />
        </LazyLoad>
      </Zoom>
    </div>
  )
})
