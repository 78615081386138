import React from 'react'
import { Helmet } from 'react-helmet'
import { Carousel } from 'react-responsive-carousel'
import { isBrowser } from 'react-device-detect'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import WorkCard from './components/work-card'
import carouselData from './carousel-data.json'
import ourJobsData from './our-jobs-data.json'
import './home.scss'
import { Link } from 'react-router-dom'

export function HomePage() {
  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Execução Engenharia - Construções, Reformas e Incorporações</title>
        <meta
          name="description"
          content="A Construtora Execução Engenharia tem como objetivo, construir ideias e sonhos dos nossos clientes.
            Seja uma casa, um prédio ou um empreendimento, estamos prontos para fazer sua obra.
            nossos serviços são reconhecidos pela qualidade, segurança e compromisso com prazos e
            custos"
        />
        <meta property="og:url" content="https://www.execucao-engenharia.com.br/" />
        <link rel="canonical" href="https://www.execucao-engenharia.com.br"></link>
      </Helmet>

      <section className="home-section-1">
        <div data-aos="fade-in">
          <Carousel
            className="home-works-carousel"
            interval={5000}
            infiniteLoop
            autoPlay
            dynamicHeight={false}
            showThumbs={false}
            showStatus={false}
            swipeable={false}
            showArrows={!isBrowser}
            showIndicators={isBrowser}>
            {carouselData.map((item, index) => (
              <div key={index} className="slide-content">
                <img className="slide-content-image" src={item.image} alt="carousel media" />

                <p className="slide-content-legend">{item.name}</p>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      <section className="home-section-2">
        <div className="container">
          <h1 className="home-section-2-title">Construções, reformas e incorporações</h1>
          <p className="home-section-2-body" data-aos="fade-up">
            A Construtora Execução Engenharia tem como objetivo, construir ideias e sonhos dos nossos clientes.
            Seja uma casa, um prédio ou um empreendimento, estamos prontos para fazer sua obra.
            nossos serviços são reconhecidos pela qualidade, segurança e compromisso com prazos e
            custos.
          </p>
        </div>
      </section>

      <section className="home-section-3">
        <div className="container">
          <h2 className="home-section-3-title">Nossos serviços:</h2>
          <div className="row">
            <div className="col-md-4 home-section-3-cardContainer">
              <div
                className="home-section-3-card"
                data-aos={isBrowser ? 'fade-left' : 'fade-in'}
                data-aos-duration={isBrowser ? '300' : '300'}
                data-aos-offset={isBrowser ? '0' : '200'}>
                <i className="fas fa-building"></i>

                <div className="home-section-3-card-mainContent">
                  <p className="home-section-3-card-title">REFORMAS</p>
                  <p className="home-section-3-card-subtitle">
                    Realizamos construções de edifícios e casas além de reformas, ampliações e
                    modernização de instalações existentes.
                  </p>
                </div>
              </div>

              <div
                className="home-section-3-card"
                data-aos={isBrowser ? 'fade-left' : 'fade-in'}
                data-aos-duration={isBrowser ? '400' : '300'}
                data-aos-offset={isBrowser ? '0' : '200'}>
                <img src={require('assets/imgs/icons/floor.svg')} alt="floor" />
                <div className="home-section-3-card-mainContent">
                  <p className="home-section-3-card-title">PORCELANATARIA</p>
                  <p className="home-section-3-card-subtitle">
                    Somos especialistas em instalações de porcelanato em grandes formatos, pastilhas
                    e pedras.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 home-section-3-cardContainer">
              <div
                className="home-section-3-card"
                data-aos={isBrowser ? 'fade-left' : 'fade-in'}
                data-aos-duration={isBrowser ? '400' : '300'}
                data-aos-offset={isBrowser ? '0' : '200'}>
                <img src={require('assets/imgs/icons/roof.svg')} alt="roof icon" />
                <div className="home-section-3-card-mainContent">
                  <p className="home-section-3-card-title">TELHADOS</p>
                  <p className="home-section-3-card-subtitle">
                    Instalações de telhado colonial e zinco com estrutura metálica e reformas.
                  </p>
                </div>
              </div>

              <div
                className="home-section-3-card"
                data-aos={isBrowser ? 'fade-left' : 'fade-in'}
                data-aos-duration={isBrowser ? '500' : '300'}
                data-aos-offset={isBrowser ? '0' : '200'}>
                <img src={require('assets/imgs/icons/elevator.svg')} alt="elevator icon" />
                <div className="home-section-3-card-mainContent">
                  <p className="home-section-3-card-title">ELEVADORES</p>
                  <p className="home-section-3-card-subtitle">
                    Executamos serviços de reformas e modernizações de elevadores.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 home-section-3-cardContainer">
              <div
                className="home-section-3-card"
                data-aos={isBrowser ? 'fade-left' : 'fade-in'}
                data-aos-duration={isBrowser ? '500' : '300'}
                data-aos-offset={isBrowser ? '0' : '200'}>
                <img src={require('assets/imgs/icons/roof.svg')} alt="roof icon" />
                <div className="home-section-3-card-mainContent">
                  <p className="home-section-3-card-title">GESSO</p>
                  <p className="home-section-3-card-subtitle">
                    Trabalhamos com revestimento 3d de gesso, drywall e servicoes de rebaixamento
                  </p>
                </div>
              </div>

              <div
                className="home-section-3-card"
                data-aos={isBrowser ? 'fade-left' : 'fade-in'}
                data-aos-duration={isBrowser ? '550' : '300'}
                data-aos-offset={isBrowser ? '0' : '200'}>
                <img src={require('assets/imgs/icons/pool.svg')} alt="pool" />
                <div className="home-section-3-card-mainContent">
                  <p className="home-section-3-card-title">PISCINA</p>
                  <p className="home-section-3-card-subtitle">
                    Realizamos o projeto completo com revestimento e impermeabilização em todas as
                    etapas evitando a necessidade de manutenção.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-section-4">
        <div className="container">
          <h2 className="home-section-4-title">Trabalhos realizados:</h2>
          <div className="row">
            {ourJobsData.map((work, i) => (
              <div
                key={work.id}
                className="col-sm"
                data-aos={isBrowser ? 'fade-up' : 'fade-in'}
                data-aos-delay={(i + 1) * 100}>
                <Link style={{ textDecoration: 'none' }} to={`/work/${work.id}`}>
                  <WorkCard title={work.name} subtitle={work.type} imgSrc={work.image} />
                </Link>
              </div>
            ))}
          </div>

          <div className="row button-container" data-aos="fade-in">
            <Link to="portfolio" className="button">
              VEJA TODOS
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}
