import { useMemo } from 'react'

function getOrientation(imgUri) {
  if (imgUri) {
    let orientation = ''
    const img = new Image()
    img.src = imgUri

    if (img.naturalWidth > img.naturalHeight) {
      orientation = 'landscape'
    } else if (img.naturalWidth < img.naturalHeight) {
      orientation = 'portrait'
    } else {
      orientation = 'even'
    }

    return orientation
  }
}

export function useImageOrientation(imgUri) {
  return useMemo(() => getOrientation(imgUri), [imgUri])
}
