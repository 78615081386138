import React from 'react'
import { string, number, oneOfType } from 'prop-types'

import './separator.scss'

function Separator({ width = '100%', height = 1, color = '#e6ded2' }) {
  return <div className="separator" style={{ width, height, backgroundColor: color }} />
}

Separator.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  color: string,
}

export default Separator
