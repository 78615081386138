import React, { memo } from 'react'
import { string, any } from 'prop-types'

import './parallax-section.scss'

const ParallaxSection = memo(({ title, backgroundImage }) => {
  return (
    <div className="parallax-section">
      <div
        className="parallax-section-bgImage"
        data-aos="fade-in"
        data-aos-duration="1000"
        style={{ backgroundImage }}
      />

      <div
        className="parallax-section-content"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-delay="700">
        <h1 className="parallax-section-content-title">{title}</h1>
      </div>
      <div
        className="parallax-section-footer"
        data-aos-offset="-500"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="250">
        <button
          onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
          className="parallax-section-footer-button">
          <p className="parallax-section-footer-button-text">SCROLL</p>
          <img src={require('assets/imgs/icons/down-chevron.svg')} alt="arrow down" />
        </button>
      </div>
    </div>
  )
})

ParallaxSection.propTypes = {
  title: string.isRequired,
  backgroundImage: any.isRequired,
}

export default ParallaxSection
