import React from 'react'
import { Helmet } from 'react-helmet'

import ParallaxSection from 'shared/components/parallax-section/parallax-section'
import './about.scss'

const backgroundImage = require('assets/imgs/engineer-papers.jpg')

export function About() {
  return (
    <div className="about">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quem somos</title>
        <meta
          name="description"
          content="A Construtora Execução Engenharia é uma empresa baseada no Rio de Janeiro, construida sobre uma
          sólida reputação em construção e reformas. Na construtora Execução Engenharia, nenhum trabalho é
          muito grande ou pequeno, nosso objetivo executar o seu projeto com eficiência,
          segurança e garantia."
        />
        <meta property="og:url" content="https://www.execucao-engenharia.com.br/about" />
        <link rel="canonical" href="https://www.execucao-engenharia.com.br/about"></link>
      </Helmet>

      <section className="about-section-1">
        <ParallaxSection
          title="CONHEÇA NOSSA HISTÓRIA"
          backgroundImage={`url(${backgroundImage})`}
        />
      </section>
      <section className="about-section-2">
        <div className="container">
          <div className="col-md-8">
            <h3 className="about-section-2-title" data-aos="fade-in">
              QUEM SOMOS
            </h3>
            <p data-aos="fade-in">
              A Construtora Execução Engenharia é uma empresa baseada no Rio de Janeiro, construida sobre uma
              sólida reputação em construção e reformas. Na construtora Execução Engenharia, nenhum trabalho é
              muito grande ou pequeno, nosso objetivo executar o seu projeto com eficiência,
              segurança e garantia.
            </p>
            <p data-aos="fade-in">
              Realizamos sua obra! Contamos com uma equipe comprometida em executar seu projeto com
              a atenção que ele merece. Nossos serviços sao reconhecidos pela qualidade, segurança e
              compromisso com prazos e custos.
            </p>
          </div>
        </div>
      </section>

      <section className="about-section-3">
        <div className="container">
          <div className="col-md-8">
            <h3 className="about-section-3-title" data-aos="fade-in">
              NOSSOS SERVIÇOS
            </h3>
            <p data-aos="fade-in">
              A construtora Execução Engenharia atua no segmento de Construção Civil, com sede na cidade do Rio
              de Janeiro, comprometida com a Qualidade, o Meio Ambiente, a Saúde e Segurança de seus
              colaboradores e pessoas atuando em seu nome.
            </p>
            <p data-aos="fade-in">
              Trabalhamos com:
              <ul>
                <li>Edificações habitacionais comerciais e industriais</li>
                <li>Instalações especiais</li>
                <li>Instalações hidráulicas</li>
                <li>Instalações elétricas</li>
                <li>Montagens mecânicas</li>
                <li>Instalações de esgoto</li>
                <li>Instalações de telhados</li>
                <li>Confecções e instalações de porcelanataria</li>
              </ul>
            </p>
          </div>
        </div>
      </section>

      <section className="about-section-4" data-aos="fade-in">
        <div className="container">
          <div className="col-md-8">
            <h3 className="about-section-4-title">MISSÃO</h3>
            <p>
              Oferecer serviços de Engenharia de alto valor agregado, por meio de parceiros e
              profissionais qualificados e práticas econômicas sustentáveis.
            </p>
          </div>
        </div>
      </section>

      <section className="about-section-5" data-aos="fade-in">
        <div className="container">
          <div className="col-md-8">
            <h3 className="about-section-5-title">VALORES</h3>
            <ul>
              <li>
                <i className="fas fa-check"></i>Produtividade
              </li>
              <li>
                <i className="fas fa-check"></i>Confiabilidade
              </li>
              <li>
                <i className="fas fa-check"></i>Foco do Cliente
              </li>
              <li>
                <i className="fas fa-check"></i>Ética
              </li>
              <li>
                <i className="fas fa-check"></i>Gestão da Qualidade
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}
